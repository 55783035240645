import request from "./request";
// /monitor/list/realtime
// /screen/map/yearly/all/electric
// /screen/map/monitor/electric
// /instance/select/attribute
export function getStoreOption(type) {
  return request({
    url: `/algorithm/storehouse/storehouse/list/${type}`,
    method: "get",
    // data
  });
}
export function reduceEnergy(data) {
  return request({
    url: `/algorithm/service/energy/saving/compare`,
    method: "post",
    data,
  });
}
export function uploadAlg(data) {
  return request({
    url: `/algorithm/storehouse/upload`,
    method: "post",
    data,
  });
}
export function getAlgList(data) {
  return request({
    url: `/algorithm/storehouse/query`,
    method: "post",
    data,
  });
}
export function editAlgStore(data) {
  return request({
    url: `/algorithm/storehouse/edit`,
    method: "post",
    data,
  });
}
export function downloadFile(fileName) {
  return request({
    url: `/algorithm/storehouse/download/${fileName}`,
    method: "get",
  });
}
export function deleteAlgS(algorithmId) {
  return request({
    url: `/algorithm/storehouse/delete/${algorithmId}`,
    method: "get",
  });
}
export function sendAlgS(algorithmId) {
  return request({
    url: `/algorithm/storehouse/released/${algorithmId}`,
    method: "get",
  });
}

export function getAlgServiceList(data) {
  return request({
    url: `/algorithm/service/query`,
    method: "post",
    data,
  });
}
export function editAlgService(data) {
  return request({
    url: `/algorithm/service/edit`,
    method: "post",
    data,
  });
}
export function getAlgOption(type) {
  return request({
    url: `/algorithm/service/getAlgorithmList/${type}`,
    method: "get",
  });
}
export function deleteAlgService(algorithmServiceId) {
  return request({
    url: `/algorithm/service/delete/${algorithmServiceId}`,
    method: "get",
  });
}
// export function sendAlgS(algorithmId) {
//   return request({
//     url: `/algorithm/storehouse/released/${algorithmId}`,
//     method: "get"
//   });
// }

export function getDiffData(data) {
  return request({
    url: `/monitor/list/dayDiff`,
    method: "post",
    data,
  });
}
export function getAttrByL(data) {
  return request({
    url: `/instance/select/attribute`,
    method: "post",
    data,
  });
}
export function getMapHbDElec() {
  return request({
    url: `/screen/map/daily/electric`,
    method: "get",
  });
}
export function getMapHbDWater() {
  return request({
    url: `/screen/map/daily/water`,
    method: "get",
  });
}
export function getMapHbDGas() {
  return request({
    url: `/screen/map/daily/gas`,
    method: "get",
  });
}
export function getMapHbElecDialog(data) {
  return request({
    url: `/screen/map/monitor/electric`,
    method: "post",
    data,
  });
}
export function getMapHbElec() {
  return request({
    url: `/screen/map/yearly/all/electric`,
    method: "get",
  });
}
export function getMapHbWater() {
  return request({
    url: `/screen/map/yearly/all/water`,
    method: "get",
  });
}
export function getMapHbGas() {
  return request({
    url: `/screen/map/yearly/all/gas`,
    method: "get",
  });
}
export function getMapHbDetailElec() {
  return request({
    url: `/screen/map/detail/electric`,
    method: "get",
  });
}
export function getMapHbDetailWater() {
  return request({
    url: `/screen/map/detail/water`,
    method: "get",
  });
}
export function getMapHbDetailGas() {
  return request({
    url: `/screen/map/detail/gas`,
    method: "get",
  });
}
export function getMapHbElecMonitor() {
  return request({
    url: `/screen/map/monitor/electric/all`,
    method: "get",
  });
}

export function getActualCop(copType) {
  return request({
    url: `/monitor/day/cop/${copType}`,
    method: "get",
  });
}
export function getActualEC(powerType) {
  return request({
    url: `/monitor/day/diff/${powerType}`,
    method: "get",
  });
}
export function getBackImage() {
  return request({
    url: `/sys/parameter/project/overview/image`,
    method: "get",
  });
}
export function getInstanceT(typeCode) {
  return request({
    url: `/instance/notFaked/select/${typeCode}`,
    method: "get",
  });
}
export function saveRecordV(data) {
  return request({
    url: `/manual/fill/save`,
    method: "post",
    data,
  });
}
export function getDataRecordV(data) {
  return request({
    url: `/manual/fill/get`,
    method: "post",
    data,
  });
}
export function getInstanceAttrS(instanceId) {
  return request({
    url: `/instance/select/attribute/${instanceId}`,
    method: "get",
  });
}
export function getMonitorRelative(data) {
  return request({
    url: `/monitor/list/relative`,
    method: "post",
    data,
  });
}
export function getMonitorRealtime(data) {
  return request({
    url: `/monitor/list/realtime`,
    method: "post",
    data,
  });
}
export function getHomeTitle() {
  return request({
    url: `/sys/parameter/index/platform/name`,
    method: "get",
  });
}
export function getLoginSrc() {
  return request({
    url: `/sys/parameter/login/logo`,
    method: "get",
  });
}
export function getHomeSrc() {
  return request({
    url: `/sys/parameter/index/logo`,
    method: "get",
  });
}
export function getLoginTitle() {
  return request({
    url: `/sys/parameter/login/platform/name`,
    method: "get",
  });
}
export function getLoginCopyRight() {
  return request({
    url: `/sys/parameter/login/copyright`,
    method: "get",
  });
}
// export function getLoginTitle() {
//   return request({
//     url: `/base/config/template/release/${templateId}`,
//     method: "get"
//   });
// }
// export function getLoginCopyRight() {
//   return request({
//     url: `/base/config/template/release/${templateId}`,
//     method: "get"
//   });
// }

export function changeModuleStatus(templateId) {
  return request({
    url: `/base/config/template/release/${templateId}`,
    method: "get",
  });
}
export function setDefaultTree(treeId) {
  return request({
    url: `/base/config/tree/default/tree/${treeId}`,
    method: "get",
  });
}
export function getTreeDetail(treeId) {
  return request({
    url: `/base/config/tree/detail/${treeId}`,
    method: "get",
  });
}

export function updateTree(data) {
  return request({
    url: `/base/config/tree/addOrEdit`,
    method: "post",
    data,
  });
}
export function getTreeList(data) {
  return request({
    url: `/base/config/tree/list`,
    method: "post",
    data,
  });
}
export function deleteTree(treeId) {
  return request({
    url: `/base/config/tree/delete/${treeId}`,
    method: "get",
  });
}

export function getInstanceTemplate() {
  return request({
    url: `/base/config/template/released/list`,
    method: "get",
  });
}
export function getInstanceTemplateAttr(templateId) {
  return request({
    url: `/base/config/template/attrs/${templateId}`,
    method: "get",
  });
}

export function updateInstanceAttr(data) {
  return request({
    url: `/base/config/instance/update/attr`,
    method: "post",
    data,
  });
}
export function changeInstanceStatus(instanceId) {
  return request({
    url: `/base/config/instance/release/${instanceId}`,
    method: "get",
  });
}
// export function getModuleType() {
//   return request({
//     url: `/base/config/template/type`,
//     method: "get"
//   });
// }
export function getInstanceList(data) {
  return request({
    url: `/base/config/instance/list`,
    method: "post",
    data,
  });
}
export function getInstanceDetail(instanceId) {
  return request({
    url: `/base/config/instance/detail/${instanceId}`,
    method: "get",
  });
}
export function deleteInstanceType(instanceId) {
  return request({
    url: `/base/config/instance/delete/${instanceId}`,
    method: "get",
  });
}
export function deleteInstanceAttr(data) {
  return request({
    url: `/base/config/instance/delete/attr`,
    method: "post",
    data,
  });
}
export function updateInstance(data) {
  return request({
    url: `/base/config/instance/addOrEdit`,
    method: "post",
    data,
  });
}

export function updateDetailAttr(data) {
  return request({
    url: `/base/config/template/attr/update`,
    method: "post",
    data,
  });
}

export function getModuleType() {
  return request({
    url: `/base/config/template/type`,
    method: "get",
  });
}
export function getModuleList(data) {
  return request({
    url: `/base/config/template/list`,
    method: "post",
    data,
  });
}
export function getModuleDetail(templateId) {
  return request({
    url: `/base/config/template/detail/${templateId}`,
    method: "get",
  });
}
export function deleteModuleType(templateId) {
  return request({
    url: `/base/config/template/delete/${templateId}`,
    method: "get",
  });
}
export function deleteModuleAttr(data) {
  return request({
    url: `/base/config/template/delete/attr`,
    method: "post",
    data,
  });
}
export function updateModule(data) {
  return request({
    url: `/base/config/template/addOrEdit`,
    method: "post",
    data,
  });
}

export function getProjectSetting(data) {
  return request({
    url: `/base/config/project/list`,
    method: "post",
    data,
  });
}

export function getProjectSettingDetail(projectId) {
  return request({
    url: `/base/config/project/detail/${projectId}`,
    method: "get",
  });
}

export function updateProjectSetting(data) {
  return request({
    url: `/base/config/project/addOrEdit`,
    method: "post",
    data,
  });
}

export function changeProjectSetting(projectId) {
  return request({
    url: `/base/config/project/change/state/${projectId}`,
    method: "get",
  });
}
export function deleteProjectSetting(projectId) {
  return request({
    url: `/base/config/project/delete/${projectId}`,
    method: "get",
  });
}

export function setProject(projectId) {
  return request({
    url: `/common/set/current/project/${projectId}`,
    method: "get",
  });
}

export function getReportDetail(reportId) {
  return request({
    url: `/report/config/detail/${reportId}`,
    method: "get",
  });
}
export function changeAlarmStatus(alarmId) {
  return request({
    url: `/alarm/config/change/state/${alarmId}`,
    method: "post",
  });
}
export function resetPassword(userId) {
  return request({
    url: `/sys/user/reset/password/${userId}`,
    method: "post",
  });
}
export function getUserDetail(userId) {
  return request({
    url: `/sys/user/get/${userId}`,
    method: "get",
  });
}
export function changePassword(data) {
  return request({
    url: `/sys/user/update/pwd`,
    method: "post",
    data,
  });
}
export function groupSearchList(data) {
  return request({
    url: `/project/group/query`,
    method: "post",
    data,
  });
}

export function groupDetail(projectGroupId) {
  return request({
    url: `/project/group/get/${projectGroupId}`,
    method: "get",
  });
}
export function groupAdd(data) {
  return request({
    url: `/project/group/add`,
    method: "post",
    data,
  });
}
export function groupEdit(data) {
  return request({
    url: `/project/group/update`,
    method: "post",
    data,
  });
}
export function groupDel(dataPrivilegeGroupId) {
  return request({
    url: `/project/group/delete/${dataPrivilegeGroupId}`,
    method: "delete",
  });
}

export function roleSearch() {
  return request({
    url: `/role/query`,
    method: "get",
    // data
  });
}
export function roleAdd(data) {
  return request({
    url: `/role/add`,
    method: "post",
    data,
  });
}
export function roleDelete(roleId) {
  return request({
    url: `/role/${roleId}`,
    method: "delete",
  });
}
export function roleEdit(data) {
  return request({
    url: `/role/update`,
    method: "post",
    data,
  });
}
export function roleMenu(roleId) {
  return request({
    url: `/role/menu/${roleId}`,
    method: "get",
  });
}
export function roleMenuSave(data) {
  return request({
    url: `/role/menu/update`,
    method: "post",
    data,
  });
}

export function deleteUser(userId) {
  return request({
    url: `/sys/user/deleteUser/${userId}`,
    method: "post",
  });
}

export function editUser(data) {
  return request({
    url: `/sys/user/updateUser`,
    method: "post",
    data,
  });
}
export function addUser(data) {
  return request({
    url: `/sys/user/addUser`,
    method: "post",
    data,
  });
}
export function getGroupList() {
  return request({
    url: `/project/group/list`,
    method: "get",
  });
}
export function getRoleList() {
  return request({
    url: `/role/list`,
    method: "get",
  });
}
export function groupSearch(data) {
  return request({
    url: `/project/group/query`,
    method: "post",
    data,
  });
}
export function userSearch(data) {
  return request({
    url: `/sys/user/query`,
    method: "post",
    data,
  });
}

export function alarmSetDel(alarmId) {
  return request({
    url: `/alarm/config/delete/${alarmId}`,
    method: "get",
  });
}
export function alarmSetUpdate(data) {
  return request({
    url: `/alarm/config/addOrEdit`,
    method: "post",
    data,
  });
}
export function alarmSetCheck(data) {
  return request({
    url: `/alarm/config/check`,
    method: "post",
    data,
  });
}

export function alarmSearchSet(data) {
  return request({
    url: `/alarm/config/query`,
    method: "post",
    data,
  });
}

export function alarmSetGet(alarmId) {
  return request({
    url: `/alarm/config/detail/${alarmId}`,
    method: "get",
  });
}
export function alarmSetLevel() {
  return request({
    url: `/alarm/config/alarm/level`,
    method: "get",
  });
}

export function bindInstanceTree(data) {
  return request({
    url: `/report/config/bind/tree`,
    method: "post",
    data,
  });
}
export function getInstanceTree(reportId) {
  return request({
    url: `/report/config/tree/detail/${reportId}`,
    method: "get",
  });
}
export function getInstance() {
  return request({
    url: `/instance/select`,
    method: "get",
  });
}
export function getInstanceType(type) {
  return request({
    url: `/instance/select/${type}`,
    method: "get",
  });
}

export function getInstanceAttr(instanceId) {
  return request({
    url: `/instance/select/attr/${instanceId}`,
    method: "get",
  });
}
export function reportManagedeatil(reportId) {
  return request({
    url: `/report/config/detail/${reportId}`,
    method: "get",
  });
}
export function reportManageupdate(data) {
  return request({
    url: `/report/config/update`,
    method: "post",
    data,
  });
}
export function reportManagechangeStatus(reportId, data) {
  return request({
    url: `/report/config/change/state/${reportId}`,
    method: "post",
    data,
  });
}
export function reportManageDelete(reportId, data) {
  return request({
    url: `/report/config/delete/${reportId}`,
    method: "post",
    data,
  });
}

export function reportManageAdd(data) {
  return request({
    url: "/report/config/add",
    method: "post",
    data,
  });
}

export function reportManageSearch(data) {
  return request({
    url: "/report/config/query",
    method: "post",
    data,
  });
}

export function getReportType() {
  return request({
    url: "/report/config/all/type",
    method: "get",
  });
}

export function getReportTypeParams(type) {
  return request({
    url: `/report/config/get/para/${type}`,
    method: "get",
  });
}

export function reportSearch(data) {
  return request({
    url: "/report/query",
    method: "post",
    data,
  });
}
export function reportExport(data) {
  return request({
    url: "/report/export",
    method: "post",
    data,
  });
}

export function getReportList() {
  return request({
    url: "/report/select/list",
    method: "get",
  });
}

export function getSankeyData(data) {
  return request({
    url: "/energy/sankey/data",
    method: "post",
    data,
  });
}

export function getShowTypes() {
  return request({
    url: "/common/show/energy/types",
    method: "get",
  });
}

export function getAlarmDiagnosis() {
  return request({
    url: "/alarm/log/type/today/diagnosis",
    method: "get",
  });
}

export function getAlarmOverrun() {
  return request({
    url: "/alarm/log/type/today/overrun",
    method: "get",
  });
}

export function getAlarmTimeout() {
  return request({
    url: "/alarm/log/type/today/timeout",
    method: "get",
  });
}

export function getAlarmByStatus() {
  return request({
    url: "/alarm/log/status/month/num",
    method: "get",
  });
}

export function getAlarmByType() {
  return request({
    url: "/alarm/log/type/month/num",
    method: "get",
  });
}

export function getAlarmActual() {
  return request({
    url: "/alarm/log/realtime/info",
    method: "get",
  });
}

export function getAlarmMonthNew() {
  return request({
    url: "/alarm/log/new/month/num",
    method: "get",
  });
}

export function getOverallCOP() {
  return request({
    url: "/project/overview/daily/cop",
    method: "get",
  });
}
export function useEnergySearch(data) {
  return request({
    url: "/energy/analysis/data/query",
    method: "post",
    data,
  });
}

export function useEnergyForecast(data) {
  return request({
    url: "/energy/analysis/monitor/query",
    method: "post",
    data,
  });
}
export function useEnergyCompare(data) {
  return request({
    url: "/energy/analysis/compare/query",
    method: "post",
    data,
  });
}
export function getAlarmList(data) {
  return request({
    url: "/alarm/log/query",
    method: "post",
    data,
  });
}
export function getAlarmDetail(id) {
  return request({
    url: `/alarm/log/detail/${id}`,
    method: "get",
  });
}
export function getMultiAttr(data) {
  return request({
    url: "/common/instances/attrs",
    method: "post",
    data,
  });
}
export function getOverallElecSame() {
  return request({
    url: "/energy/overview/compare/monthly/electric",
    method: "get",
  });
}
export function getOverallAirSame() {
  return request({
    url: "/energy/overview/compare/monthly/gas",
    method: "get",
  });
}
export function getOverallHeatSame() {
  return request({
    url: "/energy/overview/compare/monthly/heat",
    method: "get",
  });
}
export function getOverallSteamSame() {
  return request({
    url: "/energy/overview/compare/monthly/steam",
    method: "get",
  });
}
export function getOverallWaterSame() {
  return request({
    url: "/energy/overview/compare/monthly/water",
    method: "get",
  });
}
export function getElecDay() {
  return request({
    url: "/energy/overview/daily/electric",
    method: "get",
  });
}
export function getAirDay() {
  return request({
    url: "/energy/overview/daily/gas",
    method: "get",
  });
}
export function getHeatDay() {
  return request({
    url: "/energy/overview/daily/heat",
    method: "get",
  });
}
export function getSteamDay() {
  return request({
    url: "/energy/overview/daily/steam",
    method: "get",
  });
}
export function getWaterDay() {
  return request({
    url: "/energy/overview/daily/water",
    method: "get",
  });
}
export function getElecMonth() {
  return request({
    url: "/energy/overview/monthly/electric",
    method: "get",
  });
}

export function getAirMonth() {
  return request({
    url: "/energy/overview/monthly/gas",
    method: "get",
  });
}
export function getHeatMonth() {
  return request({
    url: "/energy/overview/monthly/heat",
    method: "get",
  });
}
export function getSteamMonth() {
  return request({
    url: "/energy/overview/monthly/steam",
    method: "get",
  });
}
export function getWaterMonth() {
  return request({
    url: "/energy/overview/monthly/water",
    method: "get",
  });
}
export function getTypeCompare(data) {
  return request({
    url: "/analysis/type/compare",
    method: "post",
    data,
  });
}
export function getTimeCompare(data) {
  return request({
    url: "/analysis/time/compare",
    method: "post",
    data,
  });
}
export function getColdDashboard(data) {
  return request({
    url: "/freezer/dashboard",
    method: "post",
    data,
  });
}
export function getColdPercent(data) {
  return request({
    url: "/freezer/elec/pie",
    method: "post",
    data,
  });
}
export function getColdChart(data) {
  return request({
    url: `/freezer/energy/chart`,
    method: "post",
    data,
  });
}
export function getReportTable(data) {
  return request({
    url: "/report/query/table",
    method: "post",
    data,
  });
}
export function getDataSearchTable(data) {
  return request({
    url: "/analysis/data/query/table",
    method: "post",
    data,
  });
}

export function getDataSearch(data) {
  return request({
    url: "/analysis/data/query",
    method: "post",
    data,
  });
}
export function loginApi(data) {
  return request({
    url: "/api/auth/login",
    method: "post",
    data,
  });
}
export function loginOut(data) {
  return request({
    url: "/api/auth/logout",
    method: "post",
    data,
  });
}
export function getBigElecP(data) {
  return request({
    url: "/screen/home/energy/sum",
    method: "post",
    data,
  });
}
export function getBigElecProvide(data) {
  return request({
    url: "/screen/map/generated/power/rate",
    method: "post",
    data,
  });
}
export function getProjects() {
  return request({
    url: "/common/current/projects",
    method: "get",
    // data
  });
}

export function getEnergy() {
  return request({
    url: "/common/current/energy/types",
    method: "get",
    // data
  });
}
export function getEnergyAttr(energyTypeId) {
  return request({
    url: `/common/energy/attrs/${energyTypeId}`,
    method: "get",
    // data
  });
}
export function getTreeInfo(data) {
  // debugger
  return request({
    url: "/common/trees",
    method: "post",
    data,
  });
}
export function getTree(treeId) {
  return request({
    url: `/common/tree/info/${treeId}`,
    method: "post",
    // data
  });
}
export function getTreeOption(data) {
  return request({
    url: `/common/instance/attrs`,
    method: "post",
    data,
  });
}
export function getBigElecM(data) {
  return request({
    url: `/screen/map/monthly/electric`,
    method: "get",
    data,
  });
}
export function getBigWaterM(data) {
  return request({
    url: `/screen/map/monthly/water`,
    method: "get",
    data,
  });
}
export function getBigGasM(data) {
  return request({
    url: `/screen/map/monthly/gas`,
    method: "get",
    data,
  });
}
export function getBigHeatM(data) {
  return request({
    url: `/screen/map/monthly/heat`,
    method: "get",
    data,
  });
}
export function getBigSteamM(data) {
  return request({
    url: `/screen/map/monthly/steam`,
    method: "get",
    data,
  });
}
export function getBigElecActual(data) {
  return request({
    url: `/screen/map/used/power/rate`,
    method: "get",
    data,
  });
}
export function getYearChart(data) {
  return request({
    url: `/screen/map/yearly/electric`,
    method: "get",
    data,
  });
}
export function getYearChartWater(data) {
  return request({
    url: `/screen/map/yearly/water`,
    method: "get",
    data,
  });
}
export function getYearChartAir(data) {
  return request({
    url: `/screen/map/yearly/gas`,
    method: "get",
    data,
  });
}
export function getYearChartHeat(data) {
  return request({
    url: `/screen/map/yearly/heat`,
    method: "get",
    data,
  });
}
export function getYearChartSteam(data) {
  return request({
    url: `/screen/map/yearly/steam`,
    method: "get",
    data,
  });
}
export function getDayElecUse(data) {
  return request({
    url: `/project/overview/daily/electric`,
    method: "get",
    data,
  });
}
export function getDayColdUse(data) {
  return request({
    url: `/project/overview/daily/cold`,
    method: "get",
    data,
  });
}
export function getDayAirUse(data) {
  return request({
    url: `/project/overview/daily/gas`,
    method: "get",
    data,
  });
}
export function getDayHeatUse(data) {
  return request({
    url: `/project/overview/daily/heat`,
    method: "get",
    data,
  });
}
export function getDayWaterUse(data) {
  return request({
    url: `/project/overview/daily/water`,
    method: "get",
    data,
  });
}

export function getMenu() {
  return request({
    url: "/sys/user/getMenu",
    method: "get",
  });
}
export function logOut() {
  return request({
    url: `/api/auth/logout`,
    method: "post",
  });
}
export function addInfo(data) {
  return request({
    url: `/lapi/api/v1/enterprise`,
    method: "post",
    data,
  });
}
export function viewInfo() {
  return request({
    url: `/lapi/api/v1/enterprise`,
    method: "get",
  });
}
export function editInfo(id, data) {
  return request({
    url: `/lapi/api/v1/enterprise/${id}`,
    method: "put",
    data,
  });
}
export function queryOriginal(data) {
  return request({
    url: "/apis/data/history",
    method: "post",
    data,
  });
}
export function querySn() {
  return request({
    url: "/apis/data/device/list",
    method: "get",
  });
}
export function queryTopic(sn) {
  return request({
    url: `/apis/data/topic/list/${sn}`,
    method: "get",
  });
}
export function noTopic(sn) {
  return request({
    url: `/apis/data/one/${sn}`,
    method: "get",
  });
}
// 获取所有的项目列表
export function allprojects() {
  return request({
    url: `/base/config/project/all/projects`,
    method: "get",
  });
}

// 中关村大屏数据查询
// 根据Key查询数据字典
export function screenQuery(sn) {
  return request({
    url: `/zgc/screen/query/${sn}`,
    method: "get",
  });
}
// 根据Key查询数据字典
export function multipleQuery(data) {
  return request({
    url: `/zgc/screen/multiple/query`,
    method: "post",
    data,
  });
}

// 中关村大屏-综合能耗统计

export function periodDiff(data) {
  return request({
    url: `/zgc/screen/period/diff`,
    method: "post",
    data,
  });
}

// 中关村大屏同比环比
export function monthData(data) {
  return request({
    url: `/zgc/screen/month/data`,
    method: "post",
    data,
  });
}
// 根据时间获取节能
export function yearEnergySaving(data) {
  return request({
    url: `/zgc/screen/year/energy/saving`,
    method: "post",
    data,
  });
}
// 获取节能累计
export function totalSavingFee(data) {
  return request({
    url: `/zgc/screen/total/saving/fee`,
    method: "post",
    data,
  });
}
// 获取累计节排
export function totalReduction(data) {
  return request({
    url: `/zgc/screen/total/reduction`,
    method: "post",
    data,
  });
}
// 大屏综合能耗分析-综合
export function comprehensiveData(data) {
  return request({
    url: `/zgc/screen/comprehensive/data/query`,
    method: "post",
    data,
  });
}
// 大屏综合能耗分析
export function pointQuery(data) {
  return request({
    url: `/zgc/screen/point/data/query`,
    method: "post",
    data,
  });
}
// 单位面积综合能耗
export function comprehensiveArea(data) {
  return request({
    url: `/zgc/screen/comprehensive/area/query`,
    method: "post",
    data,
  });
}
// 单位面积综合能耗
export function comprehensivePerson(data) {
  return request({
    url: `/zgc/screen/comprehensive/person/query`,
    method: "post",
    data,
  });
}
// 综合能耗柱状分析-综合能耗
export function optimizationData(data) {
  return request({
    url: `/zgc/screen/optimization/data/query`,
    method: "post",
    data,
  });
}

export function getWorkshopMonitoringData(data) {
  return request({
    url: `/analysis/data/query`,
    method: "post",
    data,
  });
}

export function analysisDataExport(data) {
  return request({
    url: `/analysis/data/export`,
    method: "post",
    data,
  });
}
