import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

//push相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: Home
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
  // {
  //   path: '/',
  //   redirect: '/layout'
  // },

  
  // {
  //   path: "/",
  //   name: "layout",
  //   redirect: "home",
  //   component: () => import('@/layout'),
  //   meta: { title: '首页' },
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       meta: { title: '能耗地图' },
  //       component: () => import('@/views/homeMap/index'),
  //     },
  //   ]
  // },
  // {
  //   path: "/",
  //   name: "layout",
  //   redirect: "home",
  //   component: () => import('@/layout'),
  //   meta: { title: '首页' },
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       meta: { title: '能耗地图' },
  //       component: () => import('@/views/homeMap/index'),
  //     },
  //     {
  //       path: "overall",
  //       name: "overall",
  //       meta: { title: '项目概览' },
  //       component: () => import('@/views/projectOverall/index'),
  //     },
  //     {
  //       path: "actual",
  //       name: "actual",
  //       meta: { title: '实时监测' },
  //       component: () => import('@/views/actualTime/index'),
  //     },
  //     {
  //       path: 'resource-management',
  //       name: 'resourceManagement',
  //       meta: { title: '能源管理' },
  //       component: () => import('@/components/blank'),
  //       children: [
  //         {
  //           path: 'resource-overall',
  //           name: 'resourceOverall',
  //           meta: { title: '能耗总览' },
  //           component: () => import('@/views/resourceManage/resouceOverall/index'),
  //         },
  //         {
  //           path: 'resource-analyze',
  //           name: 'resourceAnalyze',
  //           meta: { title: '能耗分析' },
  //           component: () => import('@/views/resourceManage/resouceAnalyze/index'),
  //         },
  //         {
  //           path: 'resource-sanki',
  //           name: 'resourceSanki',
  //           meta: { title: '能源流向' },
  //           component: () => import('@/views/resourceManage/resourceSanki/index'),
  //         },
  //       ]
  //     },
  //     {
  //       path: 'energy-comment',
  //       name: 'energyComment',
  //       meta: { title: '能效评估' },
  //       component: () => import('@/components/blank'),
  //       children: [
  //         {
  //           path: 'cold-system',
  //           name: 'coldSystem',
  //           meta: { title: '冷冻系统' },
  //           component: () => import('@/views/energyComment/coldSystem/index'),
  //         },

  //       ]
  //     },
  //     {
  //       path: 'data-analyze',
  //       name: 'dataAnalyze',
  //       meta: { title: '数据分析' },
  //       component: () => import('@/components/blank'),
  //       children: [
  //         {
  //           path: 'data-search',
  //           name: 'dataSearch',
  //           meta: { title: '数据查询' },
  //           component: () => import('@/views/dataAnalyze/dataSearch/index'),
  //         },
  //         {
  //           path: 'category-analyze',
  //           name: 'categoryAnalyze',
  //           meta: { title: '类比分析' },
  //           component: () => import('@/views/dataAnalyze/categoryAnalyze/index'),
  //         },
  //         {
  //           path: 'time-analyze',
  //           name: 'timeAnalyze',
  //           meta: { title: '时比分析' },
  //           component: () => import('@/views/dataAnalyze/timeAnalyze/index'),
  //         }
  //       ]
  //     },
  //     {
  //       path: 'data-report',
  //       name: 'dataReport',
  //       meta: { title: '数据报表' },
  //       component: () => import('@/components/blank'),
  //       children: [
  //         {
  //           path: 'report-search',
  //           name: 'reportSearch',
  //           meta: { title: '报表查询' },
  //           component: () => import('@/views/dataReport/reportSearch/index'),
  //         },
  //         {
  //           path: 'report-manage',
  //           name: 'reportManage',
  //           meta: { title: '报表管理' },
  //           component: () => import('@/views/dataReport/reportManage/index'),
  //         }
  //       ]
  //     },
      // {
      //   path: 'alarm-manage',
      //   name: 'alarmManage',
      //   meta: { title: '告警管理' },
      //   component: () => import('@/components/blank'),
      //   children: [
      //     // {
      //     //   path: 'alarm-overall',
      //     //   name: 'alarmOverall',
      //     //   meta: { title: '告警总览' },
      //     //   component: () => import('@/views/alarmManage/alarmOverall/index'),
      //     // },
      //     // {
      //     //   path: 'alarm-search',
      //     //   name: 'alarmSearch',
      //     //   meta: { title: '告警查询' },
      //     //   component: () => import('@/views/alarmManage/alarmSearch/index'),
      //     // },
      //     // {
      //     //   path: 'alarm-set',
      //     //   name: 'alarmSet',
      //     //   meta: { title: '告警设置' },
      //     //   component: () => import('@/views/alarmManage/alarmSet/index'),
      //     // },
      //     {
      //       path: 'alarm-detail',
      //       name: 'alarmDetail',
      //       meta: { title: '告警详情' },
      //       component: () => import('@/views/alarmManage/alarmSearch/detail'),
      //     },
      //     {
      //       path: 'alarmset-detail',
      //       name: 'alarmsetDetail',
      //       meta: { title: '告警设置详情' },
      //       component: () => import('@/views/alarmManage/alarmSet/detail'),
      //     },
      //   ]
      // },
  //     {
  //       path: 'sys-setting',
  //       name: 'sysSetting',
  //       meta: { title: '系统设置' },
  //       component: () => import('@/components/blank'),
  //       children: [
  //         {
  //           path: 'user-setting',
  //           name: 'userSetting',
  //           meta: { title: '用户设置' },
  //           component: () => import('@/views/sysSetting/userSetting/index'),
  //         },
  //         {
  //           path: 'role-setting',
  //           name: 'roleSetting',
  //           meta: { title: '角色设置' },
  //           component: () => import('@/views/sysSetting/roleSetting/index'),
  //         },
  //         {
  //           path: 'group-setting',
  //           name: 'groupSetting',
  //           meta: { title: '项目组' },
  //           component: () => import('@/views/sysSetting/groupSetting/index'),
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   path: '/alarm-detail',
  //   name: 'alarmDetail',
  //   meta: { title: '告警详情' },
  //   component: () => import('@/views/alarmManage/alarmSearch/detail'),
  // },
  // {
  //   path: '/alarmset-detail',
  //   name: 'alarmsetDetail',
  //   meta: { title: '告警设置详情' },
  //   component: () => import('@/views/alarmManage/alarmSet/detail'),
  // },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login/index'),
  },
  // {
  //   path: '/404',
  //   component: () =>
  //     import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
  //   name: '404',
  //   // meta: {
  //   //   keepAlive: true,
  //   //   isTab: false,
  //   //   isAuth: false
  //   // }
  // },
  {
    path: "/404",
    name: "404",
    component: () => import('@/views/404'),
  },
  // {
  //   path: '*',
  //   redirect: '/404',
  // }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});
// {
//   path: 'resource-tuopu',
//   name: 'resourceTuopu',
//   meta: { title: '能源流向' },
//   component: () => import('@/views/resourceManage/resourceTuopu/index'),
// },
// {
//   path: 'cold-main',
//   name: 'coldMain',
//   component: () => import('@/views/energyComment/coldSystem/index'),
// }
// {
//   path: 'analyze-tool',
//   name: 'analyzeTool',
//   meta: { title: '能源流向' },
//   component: () => import('@/components/blank'),
//   children: [
//     {
//       path: 'about-analyze',
//       name: 'aboutAnalyze',
//       meta: { title: '能源流向' },
//       component: () => import('@/views/analyzeTool/aboutAnalyze/index'),
//     },
//     {
//       path: 'scatter',
//       name: 'scatter',
//       meta: { title: '能源流向' },
//       component: () => import('@/views/analyzeTool/scatter/index'),
//     }
//   ]
// },
export default router;
