import Vue from "vue";
import router from "./router";
import store from "./store";
import * as utils from "@/utils/utils";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
// import notification from 'ant-design-vue/es/notification'
// import { setDocumentTitle, domTitle } from '@/utils/domUtil'
// import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }); // NProgress Configuration


// const whiteList = ['login'] // no redirect whitelist

// window.addEventListener("storage", function (e) {
//   alert(e.newValue);
//   console.log(e)
// });
let i = 1;
router.beforeEach(async (to, from, next) => {
  
  if(to.query.projectCode &&  window.localStorage.getItem("PROJECTID") != !!to.query.projectCode){
    if(to.query.islink){
      window.localStorage.setItem("islink",to.query.islink)
    }
    if(to.query.accessToken){
      window.localStorage.setItem("accessToken",to.query.accessToken)
    }
    window.localStorage.setItem("PROJECTID",to.query.projectCode)
    await store.dispatch('getMenus')
  }

  NProgress.start(); // start progress bar
  // localStorage.getItem("isLogin");
  if (to.path === "/login") {
    next();
    NProgress.done();
  } else if (to.path === "/404") {
    next();
    NProgress.done();
  }
  // else if(to.path === '/home'){
  //   next()
  //   NProgress.done()
  // }
  else if (localStorage.getItem("accessToken")) {
    // if(to.path === '/login'){
    //   next({ path: '/data-management/channel-data' })
    //   NProgress.done()
    // }
    // else{
    //   next({ path: '/data-management/channel-data' })
    //   NProgress.done()
    // }
    if (localStorage.getItem("MENU") && i === 1) {
      i++;
      // console.log('temp', localStorage.getItem("MENU"));
      let temp1 = JSON.parse(localStorage.getItem("MENU"));
      // this.$router.options.routes[0].children = menu;
      // console.log(this.$router.options.routes);

      // this.$router.addRoutes(this.$router.options.routes);
      // console.log('temp1', temp1);
      const menu = utils.formatRoutes(temp1);
      let PathArray = [];
      temp1.forEach((item) => {
        PathArray.push(item.path);
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            PathArray.push(item2.path);
          });
        }
      });
      PathArray.push("/");
      PathArray.push("/alarm-manage/alarm-detail");
      PathArray.push("/alarm-manage/alarmset-detail");
      PathArray.push("/data-report/report-detail");
      PathArray.push("/base-config/project-detail");
      PathArray.push("/base-config/module-detail");
      PathArray.push("/actualTime/workshopMonitor-detail");
      PathArray.push("/actualTime/workshopMonitor-detail2");
      PathArray.push("/actualTime/heating-system");
      PathArray.push("/data-report/report-custom/detail");

      PathArray.push("/base-config/instance-detail");
      PathArray.push("/projectOverall/zgc");
      PathArray.push("/projectOverall/jkjd");
      PathArray.push("/projectOverall/lpsj");
      
      let temp = [
        {
          path: "/",
          name: "layout",
          redirect: "home",
          component: () => import("@/layout"),
          meta: { title: "首页" },
          children: [],
        },
      ];
      console.log("menu", menu);
      temp[0].children = menu;
      temp[0].children.push({
        path: "/alarm-manage/alarm-detail",
        name: "alarmDetail",
        meta: { title: "告警详情" },
        component: () => import("@/views/alarmManage/alarmSearch/detail"),
      });
      
      temp[0].children.push({
        path: "/alarm-manage/alarmset-detail",
        name: "alarmsetDetail",
        meta: { title: "告警设置详情" },
        component: () => import("@/views/alarmManage/alarmSet/detail"),
      });
      temp[0].children.push({
        path: "/data-report/report-custom/detail",
        name: "energyCostCustomEdit",
        meta: {
          title: "自定义报表预览",
          activeMenu: "/data-report/report-custom",
        },
        component: () => import("@/views/dataReport/reportCustom/edit"),
      });

      temp[0].children.push({
        path: "/data-report/report-detail",
        name: "reportDetail",
        meta: { title: "报表详情" },
        component: () => import("@/views/dataReport/reportManage/detail"),
      });
      temp[0].children.push({
        path: "/base-config/project-detail",
        name: "projectDetail",
        meta: { title: "项目详情" },
        component: () => import("@/views/baseConfig/projectSetting/detail"),
      });
      temp[0].children.push({
        path: "/base-config/module-detail",
        name: "moduleDetail",
        meta: { title: "模板详情" },
        component: () => import("@/views/baseConfig/module/detail"),
      });
      temp[0].children.push({
        path: "/actualTime/workshopMonitor-detail",
        name: "actualTimeWorkshopMonitor",
        meta: { title: "车间监控详情" },
        component: () => import("@/views/actualTime/workshopMonitor/detail"),
      });
      temp[0].children.push({
        path: "/actualTime/workshopMonitor-detail2",
        name: "actualTimeWorkshopMonitor2",
        meta: { title: "车间监控详情" },
        component: () => import("@/views/actualTime/workshopMonitor/detail2"),
      });
      temp[0].children.push({
        path: "/base-config/instance-detail",
        name: "instanceDetail",
        meta: { title: "实例详情" },
        component: () => import("@/views/baseConfig/instance/detail"),
      });
      temp[0].children.push({
        path: "/projectOverall/zgc",
        name: "projectOverallZGC",
        meta: { title: "实例详情" },
        component: () => import("@/views/projectOverall/zgc"),
      });
      temp[0].children.push({
        path: "/projectOverall/jkjd",
        name: "projectOverallJKDJ",
        meta: { title: "金科酒店" },
        component: () => import("@/views/projectOverall/jkjd"),
      });
      temp[0].children.push({
        path: "/projectOverall/lpsj",
        name: "projectOverallLPSJ",
        meta: { title: "罗普斯金" },
        component: () => import("@/views/projectOverall/lpsj"),
      });

      router.options.routes = temp;
      router.addRoutes(temp);
      console.log("temp", temp);
      if (PathArray.indexOf(to.path) === -1) {
        next({ path: "/404" });
      } else {
        next({ ...to, replace: true });
      }
    } else {
      let temp1 = JSON.parse(localStorage.getItem("MENU"));
      let PathArray = [];
      temp1.forEach((item) => {
        PathArray.push(item.path);
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            PathArray.push(item2.path);
          });
        }
      });
      PathArray.push("/");
      PathArray.push("/alarm-manage/alarm-detail");
      PathArray.push("/alarm-manage/alarmset-detail");
      PathArray.push("/data-report/report-custom/detail");
      PathArray.push("/data-report/report-detail");
      PathArray.push("/base-config/project-detail");
      PathArray.push("/base-config/module-detail");
      PathArray.push("/actualTime/workshopMonitor-detail");
      PathArray.push("/actualTime/workshopMonitor-detail2");
      PathArray.push("/base-config/instance-detail");
      PathArray.push("/projectOverall/zgc");
      PathArray.push("/projectOverall/jkjd");
      PathArray.push("/projectOverall/lpsj");
      if (PathArray.indexOf(to.path) === -1) {
        next({ path: "/404" });
      } else {
        next();
      }
    }
    // next()
    // next({ ...to, replace: true })
    NProgress.done();
  } else if (!localStorage.getItem("accessToken")) {
    next({ path: "/login" });
    NProgress.done();
  }

  // to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  // if (Vue.ls.get(ACCESS_TOKEN)) {
  //   /* has token */
  //   if (to.path === '/user/login') {
  //     // next({ path: '/equipment-management/product-list' })
  //     next({ path: '/info-manage/base-info' })
  //     NProgress.done()
  //   } else {
  //     if (store.getters.roles.length === 0) {
  //       store
  //         .dispatch('GetInfo')
  //         .then(res => {
  //           next({ path: '/info-manage/base-info' })
  //           // next({ path: '/info-manage/base-info' })
  //           const roles = res && res.role
  //           store.dispatch('GenerateRoutes', { roles }).then(() => {
  //             // 根据roles权限生成可访问的路由表
  //             // 动态添加可访问路由表
  //             router.addRoutes(store.getters.addRouters)
  //             const redirect = decodeURIComponent(from.query.redirect || to.path)
  //             if (to.path === redirect) {
  //               // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
  //               next({ ...to, replace: true })
  //               // next({ path: '/info-manage/base-info' })
  //             } else {
  //               // 跳转到目的路由
  //               next({ path: redirect })
  //               // next({ path: '/info-manage/base-info' })
  //             }
  //           })
  //         })
  //         .catch(() => {
  //           // notification.open({
  //           //   message: '提示',
  //           //   description: '请重新登录'
  //           // })
  //           store.dispatch('Logout').then(() => {
  //             // next({ path: '/user/login', query: { redirect: to.fullPath } })
  //             next({ path: '/user/login' })
  //           })
  //         })
  //     } else {
  //       store
  //         .dispatch('GetInfo').then(res => {
  //           next()
  //         })
  //     }
  //   }
  // } else {
  //   if (whiteList.includes(to.name)) {
  //     // 在免登录白名单，直接进入
  //     // console.log(to.name)
  //     next()
  //   } else {
  //     // next({ path: '/user/login', query: { redirect: to.fullPath } })
  //     next({ path: '/user/login' })
  //     NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
  //   }
  // }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 *    <a v-action:edit @click="edit(record)">修改</a>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *  - 当后台权限跟 pro 提供的模式不同时，只需要针对这里的权限过滤进行修改即可
 *
 *  @see https://github.com/sendya/ant-design-pro-vue/pull/53
 */
const action = Vue.directive("action", {
  bind: function (el, binding, vnode) {
    const actionName = binding.arg;
    const roles = store.getters.roles;
    const elVal = vnode.context.$route.meta.permission;
    const permissionId = (elVal instanceof String && [elVal]) || elVal;
    roles.permissions.forEach((p) => {
      if (!permissionId.includes(p.permissionId)) {
        return;
      }
      if (p.actionList && !p.actionList.includes(actionName)) {
        (el.parentNode && el.parentNode.removeChild(el)) ||
          (el.style.display = "none");
      }
    });
  },
});

export { action };
