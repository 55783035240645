// import moment from 'moment';
// import menuData from '@/config/menu';
export const getAccount = () => {
	const str = localStorage.getItem('account');
	if (!str) {
		return null;
	}
	return JSON.parse(str);
};
export function formatRoutes(routes) {
	// debugger
	let fmtRoutes = []
	routes.forEach(routes => {
		// if (routes.children) {
		if (routes.children && routes.children.length > 0) {
			routes.children = formatRoutes(routes.children)
		}
		// }
		let fmtRoute = {
			path: routes.path || '',
			component: resolve => require(['@/views' + routes.component + '.vue'], resolve),
			// '@/components/admin/' + routes.component + '.vue'
			// component (resolve) { require([`${routes.component}.vue`], resolve) },
			name: routes.menuId,
			children: routes.children
		}
		// fmtRoute.component = () => import(`${routes.component}`)
		// if (routes.children) {

		// 	if (routes.children.length === 0) {
		// 		delete fmtRoute.children
		// 	}
		// }
		fmtRoutes.push(fmtRoute)
	})
	return fmtRoutes
}
// export const getToken = () => {
// 	const str = sessionStorage.getItem('token');
// 	if (!str) {
// 		return null;
// 	}
// 	return JSON.parse(str);
// };

// export const formatComplexDate = date => {
// 	return moment(date).format('YYYY-MM-DD HH:mm');
// };

// export const formatDate = date => {
// 	return moment(date).format('YYYY-MM-DD');
// };

// export const secondToMin = result => {
// 	let m = Math.floor(result / 60);
// 	let s = Math.floor(result % 60);
// 	if (m < 10) {
// 		m = '0' + m;
// 	}
// 	if (s < 10) {
// 		s = '0' + s;
// 	}
// 	return (result = m + ':' + s);
// };
// export const validateNumber = (value, callback) => {
//   if (!value) {
//     callback();
//     return;
//   }
//   if (!/^[0-9]+$/.test(value)) {
//     callback(new Error("只能包含数字"));
//   } else {
//     callback();
//   }
// };
// export const validatePhone = (value, callback) => {
// 	if (!value) {
// 		callback(new Error('请输入手机号'))
// 		return;
// 	}
// 	if (!(/^1[3456789]\d{9}$/.test(value))) {
// 		callback(new Error('手机号码格式不正确'))
// 	} else {
// 		callback()
// 	}
// };
// export const validateEmail = (value, callback) => {
// 	if (!value) {
// 		callback()
// 		return;
// 	}
// 	if (!(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(value))) {
// 		callback(new Error('邮箱格式不正确'))
// 	} else {
// 		callback()
// 	}
// };
// export const flatten = (data) => {
// 	let menuArr = []
// 	const spread = (menus) => {
// 		for (var i = 0; i < menus.length; i++) {
// 			const menu = menus[i]
// 			if (menu.children) {
// 				spread(menu.children)
// 				delete menu.children
// 			}
// 			menuArr.push(menu)
// 		}
// 	}
// 	spread(data)
// 	return menuArr;
// }
// export const getAuthData = () => {
// 	const formatMenuData = menuData.map(v => {
// 		let children;
// 		// 有子菜单，加上子菜单。没子菜单，加上action
// 		if (v.children) {
// 			children = v.children.map(sv => {
// 				let sChildren;
// 				if (sv.children) {
// 					sChildren = sv.children.map(ssv => {
// 						let actions;
// 						actions = ssv.actions && ssv.actions.map(sssv => {
// 							return {
// 								name: sssv.name,
// 								path: sssv.action,
// 								type: 'ACTION'
// 							}
// 						});
// 						return {
// 							name: ssv.name,
// 							path: ssv.route,
// 							type: 'NODE',
// 							children: actions
// 						}
// 					});
// 				} else {
// 					sChildren = sv.actions && sv.actions.map(ssv => {
// 						return {
// 							name: ssv.name,
// 							path: ssv.action,
// 							type: 'ACTION'
// 						}
// 					});
// 				}
// 				return {
// 					name: sv.name,
// 					path: sv.route,
// 					type: 'NODE',
// 					children: sChildren
// 				}
// 			});
// 		} else {
// 			children = v.actions && v.actions.map(sv => {
// 				return {
// 					name: sv.name,
// 					path: sv.action,
// 					type: 'ACTION'
// 				}
// 			});
// 		}
// 		return {
// 			name: v.name,
// 			path: v.route,
// 			type: 'NODE',
// 			children: children
// 		}
// 	});
// 	return formatMenuData;
// }