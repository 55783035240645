import axios from "axios";
import Vue from "vue";
import router from "@/router";
// import base from "./base"
// import {
//   ACCESS_TOKEN
// } from '@/store/mutation-types'
const service = axios.create({
  // baseURL: 'http://192.168.2.17:8088',
  // baseURL: 'http://192.168.2.102:8088',
  // baseURL: 'http://192.168.2.3:8088',
  // baseURL: 'http://192.168.2.9:8088',
  // baseURL: 'http://192.168.0.124:8088',
  // baseURL: "http://121.36.229.175:8088", //哈博后台地址

  // baseURL: 'http://192.168.2.22:8088',
  // baseURL: "https://linkgapie.com:8087", //菱感后台地址
  baseURL: process.env.VUE_APP_BASE_API, //菱感后台地址
  // baseURL: "http://192.168.2.8:8088",
  // baseURL: 'http://192.168.188.92:10600',
  timeout: 60000, // 请求超时时间
});
// axios.defaults.withCredentials = true; //请求session携带
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 设置请求头，增加token
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = token;
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    if (config.method === "put") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const data = res;
    // 请求报错
    if (data.data.code === 1) {
      Vue.prototype.$message.error(data.data.msg);
    }
    // token过期
    if (data.data.msg === "访问令牌已过期" || data.data.code == 403) {
      // 不在登录则清除token回到登录页面
      if (router.history.current.name !== "login") {
        localStorage.removeItem("accessToken");
        router.push({ name: "login" });
      }
    }
    return data;
  },
  (error) => {
    console.log("响应拦截：" + error.response);
    if (error.response) {
      if (error.response.data.msg) {
        Vue.prototype.$message.error(error.response.data.msg);
        // token过期
        if (error.response.data.code == 403) {
          // 不在登录则清除token回到登录页面
          if (router.history.current.name !== "login") {
            localStorage.removeItem("accessToken");
            router.push({ name: "login" });
          }
        }
      } else {
        Vue.prototype.$message.error("请求出错");
      }
    } else {
      Vue.prototype.$message.error("请求出错");
    }
    return Promise.reject(error);
  }
);
export default service;
